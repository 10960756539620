import React from "react"
import type { HeadFC } from "gatsby"
import styled from "@emotion/styled"
import "../css/reset.css"
import "../css/global.css"
import Google from "../components/Google"
import Layout from "../components/Layout"
import { SEO } from "../components/SEO"

const Container = styled.div`
  padding: 10px;
  max-width:900px;
  margin: 0 auto;
`;

const NotAllowedPage = () => {
  return <>
    <Google />
    <Layout>
      <Container>
        <h1>403 - Forbidden</h1>
        <p>This page could not be found. Return <a href="/">Home</a></p>
      </Container>
    </Layout>
  </>
}

export default NotAllowedPage

export const Head: HeadFC = () => <>
  <SEO title="You are not allowed to access this page" description="You are not allowed to access this page" path={"/"} />
  <meta name="robots" content="noindex" />
</>
